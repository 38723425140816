
.main-section--media{
    background-image: url(/images/main/media-bg.svg);
    background-position: center calc(var(--page-section-padding-y) / 2);
    background-repeat: no-repeat;
    background-size: 100% auto;
    &__left{
        position: relative;
        z-index: 5;
    }
}
::v-deep{
    .v-bottom-sheet{
        background-color: rgba(0, 0, 0, .8) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
    }
    .swiper-slide{
        transition: 0.25s ease-out;
        .media-card__image{
            transform-origin: left center;
            transition: 0.25s ease-out;
        } 
    }
    .swiper-slide:not(.swiper-slide-active):not(.swiper-slide-next){
        opacity: 0;
        visibility: hidden;           
    }
    .swiper-slide:not(.swiper-slide-active){
        .media-card__image{
            transform: scale(0.66);
        }                
    }
}
    
.media-contents{
    position: relative;
    ::v-deep{
        .swiper-slide:not(.swiper-slide-active){
            cursor: default;
            pointer-events: none;
        }
    }
}

.media-card{
    position: relative;
    &__image{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        max-width: 100%;
        height: 100%;
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background-color: rgba(0, 0, 0, .6);
        }
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 48px;
            height: 48px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-image: url(/images/icon/icon-play.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
}

@media (min-width:576px){
}
@media (min-width:768px){
    .main-section--media{
        background-position: center;
    }
    ::v-deep{
        .v-bottom-sheet{
            .v-btn--absolute.v-btn--top.v-btn--right{
                top: 50px;
                right: 50px;
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){ 
    .media-contents{
        position: relative;
        ::v-deep{
            .swiper-container{
                overflow: visible;
                margin: 0;
            }
        }
    }
}
