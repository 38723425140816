
::v-deep {
    .swiper--recent {
        .swiper-wrapper {
            .swiper-slide:nth-child(2n - 1) {
                .row {
                    flex-direction: column-reverse;
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    ::v-deep {
        .swiper--recent {
            &.swiper-container {
                overflow: visible;
            }
        }
    }
}
