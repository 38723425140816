
@media (min-width:576px){
}
@media (min-width:768px){
    .txt + .txt{
        margin-top: 4px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

