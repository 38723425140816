
.card-inner {
    position: relative;
}
.icon-plus-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
