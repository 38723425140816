
.header-body {
    height: var(--header-body-height);
    .container {
        display: flex;
        align-content: center;
        height: 100%;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

