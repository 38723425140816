
.sitemap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    &,
    &__contents,
    &__overlay {
        opacity: 0;
        visibility: hidden;
        transition: all ease-out 0.2s;
    }
    &__contents {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 6;
        display: block;
        width: 100%;
        height: 100vh;
        padding: var(--header-height) 0 60px;
        .container {
            height: 100%;
            // overflow-x: hidden;
            // overflow-y: auto;
            &::-webkit-scrollbar {
                width: 30px;
                border-left: 24px solid transparent;
                background: var(--v-grey-lighten5);
                background-clip: padding-box;
            }
            &::-webkit-scrollbar-thumb {
                width: 6px;
                border-left: 24px solid transparent;
                background: var(--v-grey-lighten4);
                background-clip: padding-box;
            }
        }
    }
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -5;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);
    }
    &__logo{
        width: 100%;
        position: fixed;
        top: 26px;
        left: 0;
        z-index: 6;
    }
}
.sitemap-open {
    .sitemap {
        &,
        &__contents,
        &__overlay {
            opacity: 1;
            visibility: visible;
        }
        &__overlay {
            z-index: 5;
        }
    }
}

