
.icon-plus {
    position: relative;
    transform: rotate(0);
    transition: 0.25s ease-out;
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 2px;
        display: block;
        background-color: currentColor;
    }
    &::after {
        transform: translate(-50%, -50%) rotate(90deg);
    }
    &.icon--size {
        &-xx-small {
            width: 16px;
            height: 16px;
        }
        &-x-small {
            width: 18px;
            height: 18px;
        }
        &-small {
            width: 30px;
            height: 30px;
        }
        &-default {
            width: 30px;
            height: 30px;
        }
        &-large {
            width: 54px;
            height: 54px;
        }
        &-x-large {
            width: 58px;
            height: 58px;
        }
        &-xx-large {
            width: 64px;
            height: 64px;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .plus-btn-card:hover{
        .icon-plus{
            transform: rotate(90deg);
        }
    }
}
@media (min-width:1200px){
}

