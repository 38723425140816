
.inline-group {
    > ul {
        margin: 0 -6px;
        > li {
            float: left;
            padding: 0 6px;
        }
    }

    .clear {
        clear: both;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .inline-group {
        > ul {
            margin: 0 -8px;
            > li {
                padding: 0 8px;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
