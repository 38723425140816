
.tit__head{
    display: inline-block;
    font-size: 8rem;
    color: var(--v-primary-lighten4);
    line-height: var(--tit-font-size-xxl);
}
@media (min-width:576px){
}
@media (min-width:768px){
    .tit__head{
        font-size: 12rem;
    }
}
@media (min-width:1024px){
    .tit__head{
        font-size: 16rem;
    }
}
@media (min-width:1200px){
    .tit__head{
        font-size: 20rem;
        line-height: 150px;
    }
}

