
.mobile-header {
    &-head,
    &-body,
    &-foot {
        -webkit-transition: all ease-out 0.2s;
        -ms-transition: all ease-out 0.2s;
        transition: all ease-out 0.2s;
    }
    // 스크롤시
    &.scroll-active {
        ::v-deep {
            .mobile-header {
                &-body {
                    background-color: rgba(38, 46, 55, 0.9) !important;
                }
            }
        }
    }
}
