
// btn-top-secondary
.btn-top-secondary {
    &.v-size--default{
        font-size: 1.8rem !important;
        font-family: var(--font-secondary);
        font-weight: 400 !important;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
