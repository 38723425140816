
.lnb--all-border ::v-deep {
    line-height: 1.35;
    padding: 32px 0;
    background-color: transparent;
    border-top: 1px solid var(--v-grey-lighten3);
    border-bottom: 1px solid var(--v-grey-lighten3);
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    > li {
        > a {
            font-weight: 400;
            color: var(--v-grey-lighten1);
            padding: 8px;
            &:hover {
                color: var(--v-grey-darken4);
            }
        }
    }
}
.gnb__item {
    &.active {
        .lnb {
            border-left: 1px solid var(--v-grey-lighten3);
            border-right: 1px solid var(--v-grey-lighten3);
        }
    }
}
