
.btn-hamburger{
    ::v-deep{
        span,
        span::before,
        span::after{
            background-color: var(--v-grey-darken4) !important;
        }
    }
}
.mobile-header {
    &.scroll-active {
        ::v-deep{
            span,
            span::before,
            span::after{
                background-color: #fff !important;
            }
        }
        .logo{
            ::v-deep{
                a{
                    background-image: url(/images/logo2.svg) !important;
                }
            }
        }
    }
}

.app--main{
    .mobile-header {
        ::v-deep{
            span,
            span::before,
            span::after{
                background-color: #fff !important;
            }
        }
        .logo{
            ::v-deep{
                a{
                    background-image: url(/images/logo2.svg) !important;
                }
            }
        }
    }
}
