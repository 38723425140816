
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                width: 20px !important;
            }
            &-x-small {
                width: 24px !important;
            }
            &-small {
                width: 28px !important;
            }
            &-default {
                width: 32px !important;
            }
            &-large {
                width: 50px !important;
            }
            &-x-large {
                width: 54px !important;
            }
            &-xx-large {
                width: 58px !important;
            }
        }
    }
    &-up,
    &-down {
        // Size
        &.icon--size {
            &-xx-small {
                height: 20px !important;
            }
            &-x-small {
                height: 24px !important;
            }
            &-small {
                height: 28px !important;
            }
            &-default {
                height: 32px !important;
            }
            &-large {
                height: 50px !important;
            }
            &-x-large {
                height: 54px !important;
            }
            &-xx-large {
                height: 58px !important;
            }
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .icon--direction {
        &-right,
        &-down {
            transform: rotate(180deg);
        }
        &-left,
        &-right {
            // Size
            &.icon--size {
                &-xx-small {
                    width: 24px !important;
                }
                &-x-small {
                    width: 28px !important;
                }
                &-small {
                    width: 32px !important;
                }
                &-default {
                    width: 50px !important;
                }
                &-large {
                    width: 54px !important;
                }
                &-x-large {
                    width: 58px !important;
                }
                &-xx-large {
                    width: 62px !important;
                }
            }
        }
        &-up,
        &-down {
            // Size
            &.icon--size {
                &-xx-small {
                    height: 24px !important;
                }
                &-x-small {
                    height: 28px !important;
                }
                &-small {
                    height: 32px !important;
                }
                &-default {
                    height: 50px !important;
                }
                &-large {
                    height: 54px !important;
                }
                &-x-large {
                    height: 58px !important;
                }
                &-xx-large {
                    height: 62px !important;
                }
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

