
::v-deep{
    .v-dialog{
        &__inner{
            position: relative;
            padding-top: 44px;
            .v-toolbar{
                width: 100%;
                position: absolute;
                top: 0;
                background-color: transparent;
            }
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: var(--v-secondary-base);
                top: 0;
                left: 0;
            }
        }
        &__contents{
            max-height: calc(100vh - (var(--container-gutter) * 2) - 48px);
            overflow-x: hidden;
            overflow-y: auto;
            &::-webkit-scrollbar{width: 4px; height: 4px;}
            &::-webkit-scrollbar-thumb{width: 4px; height: 4px; background-color: var(--v-secondary-base);}
            &::-webkit-scrollbar-track{width: 4px; height: 4px; background-color: var(--v-grey-lighten3);}
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){::v-deep{
    .v-dialog{
            &__inner{
                padding-top: 48px;
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

