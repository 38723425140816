
.quick-menu{
    position: fixed;
    width: 80px;
    height: 100vh;
    right: 0;
    z-index: 102;
    &__inner{
        position: relative;
        width: 100%;
        height: 100%;
        padding: 60px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;            
            background-color: rgba(38, 46, 55, .9);            
            z-index: 6;
        }
    }
    &__buttons{
        position: relative;
        z-index: 6;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__button{
        width: 80px;
        height: 60px;
        color: #fff;
        position: relative;
        overflow: visible;
        display: flex;
        justify-content: center;
        &::after,
        &__open{
            position: absolute;
            width: 220px;
            height: 100%;
            top: 0;
            left: -220px;
        }
        &::after{
            content: "";
            display: block;       
            transform: scaleX(0);
            transform-origin: right center;
            background-color: var(--v-grey-darken3);
            border-left: 1px solid var(--v-secondary-base);  
            transition: .2s .1s ease-out;
        }
        &__open{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: .2s ease-out;
        }
        &:hover{
            color: var(--v-secondary-base);
            &::after{
                transform: scaleX(1);         
                transition: .2s ease-out;
            }
            .quick-menu__button__open{
                opacity: 1;
                visibility: visible;
                transition: .2s .1s ease-out;
            }
        }
    }
}
