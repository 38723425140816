
.app--main{
    .header:not(.scroll-active){
        .logo{
            ::v-deep{
                a{
                    background-image: url(/images/logo2.svg);
                }
            }
        }
    }
}
