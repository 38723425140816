
// btn-top-primary
.btn-top-primary {
    bottom: var(--btn-top-position-bottom) !important;
    right: var(--btn-top-position-right) !important;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
    transition: all ease-out 0.2s;
    box-shadow: none !important;
    will-change: unset !important;
    &.v-btn:not(.v-btn--has-bg) {
        background-color: #fff !important;
    }
    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .btn-top-primary {
        bottom: var(--btn-top-position-bottom) !important;
        right: var(--btn-top-position-right) !important;
        &.v-btn.v-size--default.v-btn--icon{
            width: 60px !important;
            height: 60px !important;   
        }
    }
}
@media (min-width: 1200px) {
}
