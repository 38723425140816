
.language-items {
    display: flex;
    align-items: center;
    color: var(--v-grey-darken4);

    &__icon-text,
    &__icon,
    &__text {
        color: inherit;
    }

    &__icon-text {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    &__icon {
        font-size: 2.4rem;
    }
    &__text {
        font-size: 1.4rem;
        text-transform: uppercase;
        padding-left: 2px;
    }

    > .row > .col {
        .v-divider {
            border-color: var(--v-grey-lighten3);
            height: 12px;
        }
    }

    &--light {
        color: #fff;
        .v-divider {
            border-color: rgba(255, 255, 255, 0.2) !important;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .language-items {
        &__text {
            font-size: 1.8rem;
        }
    }
}
