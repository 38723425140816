
// hamburger
.btn-hamburger {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--btn-hamburger-width);
    height: var(--btn-hamburger-width);
    z-index: 7;
    cursor: pointer;
    color: rgba(var(--btn-hamburger-color), var(--btn-hamburger-color-opacity));
    > span {
        position: relative;
        &,
        &::before,
        &::after {
            display: block;
            width: var(--btn-hamburger-width);
            height: var(--btn-hamburger-height);
            background-color: var(--btn-hamburger-color);
        }
        &::before,
        &::after {
            position: absolute;
            content: "";
            transition-property: margin, transform;
            transition-duration: 0.2s;
        }
        &::before {
            margin-top: -8px;
        }
        &::after {
            margin-top: 8px;
        }
    }

    &.active {
        > span {
            background-color: rgba(0, 0, 0, 0);
            transition-delay: 0.2s;
            &::before,
            &::after {
                // left: auto;
                // right: auto;
                margin-top: 0;
                transition-delay: 0s, 0.2s;
            }
            &::before {
                transform: rotate(45deg);
            }
            &::after {
                transform: rotate(-45deg);
            }
        }
    }

    &--pc {
        display: none;
    }
    &--mobile {
        display: inline-flex;
        // position: absolute;
        // top: 0;
        // right: 0;
        z-index: 7;
        width: var(--header-body-height);
        height: var(--header-body-height);
        margin-right: calc(var(--container-gutter) * -1);
    }

    &--light {
        color: rgba(255, 255, 255, 1);
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .btn-hamburger {
        &--pc {
            display: inline-flex;
        }
        &--mobile {
            display: none;
        }
        > span {
            &::before {
                margin-top: -12px;
            }
            &::after {
                margin-top: 12px;
            }
        }
    }
}
