
// .line {
//     display: block;
//     width: 1px;
//     height: 16px;
//     background-color: var(--v-grey-lighten4);
// }
// .dark .line {
//     background-color: rgba(255, 255, 255, 0.3);
// }
@media (min-width: 768px) {
    .swiper-btn {
        --btn-height: 80px;
    }
}
@media (min-width: 1024px) {
    .swiper-btn {
        --btn-height: 120px;
    }
}
