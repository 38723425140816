
.footer {
    &--dark {
        color: #fff;
        background-color: var(--v-grey-darken4);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .footer {
        padding-right: 80px;
    }
}
@media (min-width:1660px){
    .footer {
        padding-right: 0;
    }
}

