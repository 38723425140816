
.mobile-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    -webkit-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s;
    // fixed
    &--fixed {
        position: fixed;
    }
}
