
.mobile-nav-body--basic {
    flex: 0 1 auto;
    overflow-y: auto;
    ::v-deep {
        .container {
            padding: 0 !important;
        }

        .gnb-mo {
            &__item {
                border-bottom: 1px solid var(--border-color);
            }
            &__link {
                color: var(--v-grey-darken4) !important;
            }
            &__sub {
                background-color: var(--v-grey-lighten5);
                .gnb-mo__sub {
                    .gnb-mo__sub-link {
                        color: var(--v-grey-base) !important;
                    }
                }
            }
        }
    }
}
