
.lawyer{
    &__right{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__img{
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &--top{
        .lawyer__right{
            background-image: url(/images/main/lawyer-bg.jpg);
        }
        .lawyer__contents-wrap{
            padding: 40px 0 0 0;
        }
    }
    &--bottom{
        .lawyer__right{
            background-image: url(/images/main/lawyer-bg2.jpg);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .lawyer{
        position: relative;
        &__right{
            background-image: none !important;
            position: absolute !important;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 5;
        }
        &__title{
            transform: translateX(-50%);
            width: fit-content;
            white-space: nowrap;
        }
        &--top{
            .lawyer__contents-wrap{
                padding: 80px 0 0 30px;
            }
        }
        &--bottom{
            .lawyer__contents-wrap{
                padding-right: 30px;
            }
            &__btn-group{
                z-index: 10;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
            &__btn{
                --btn-height: 80px
            }
        }
    }
    .lawyer-info{
        overflow: hidden;
        max-height: 186px;
        flex-direction: column;
    }
}
@media (min-width:1024px){
    .lawyer{
        &--top{
            .lawyer__contents-wrap{
                padding: 120px 0 0 30px;
            }
        }
        &--bottom{
            &__btn{
                --btn-height: 120px
            }
        }
    }
}
@media (min-width:1200px){
    .lawyer{
        &--top{
            .lawyer__contents-wrap{
                padding-left: 134px;
            }
        }
        &--bottom{
            .lawyer__contents-wrap{
                padding-right: 134px;
            }
        }
    }
}

