
.quick-menu-pc{
    .quick-menu__button{
        width: 80px;
        height: 60px;
        color: #fff;
        position: relative;
        overflow: visible;
        display: flex;
        justify-content: center;
        &::after,
        &__open{
            position: absolute;
            width: 220px;
            height: 100%;
            top: 0;
            left: -220px;
        }
        &::after{
            content: "";
            display: block;       
            transform: scaleX(0);
            transform-origin: right center;
            background-color: var(--v-grey-darken3);
            border-left: 1px solid var(--v-secondary-base);  
            transition: .2s .1s ease-out;
        }
        &__open{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: .2s ease-out;
        }
        &:hover{
            color: var(--v-secondary-base);
            &::after{
                transform: scaleX(1);         
                transition: .2s ease-out;
            }
            .quick-menu__button__open{
                opacity: 1;
                visibility: visible;
                transition: .2s .1s ease-out;
            }
        }
    }
}
.quick-menu-mo{
    .quick-menu__button{
        height: 100%;
        border-bottom: 1px solid var(--v-grey-darken3);;
        &-wrap{
            min-width: calc((100% - 2px) / 3);
        }
        &__inner{
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: 500;
            font-size: 1.4rem;
        }
        &-group{
            background-color: var(--v-grey-darken3);;
            gap: 1px;
        }
        ::v-deep{
            .icon--size-default{
                width: 38px !important;
            }
        }
    }
}
