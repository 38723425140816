
.footer-head--basic {
    padding-top: var(--page-section-padding-y);
    position: relative;
    &::before{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 210px;
        top: 0;
        left: 0;
        background-image: url(/images/common/footer-bg.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1;
    }
}
@media (min-width: 576px) {
    .footer-head--basic {
        &::before{
            height: 180px;
        }
    }
}
@media (min-width: 768px) {
    .footer-head--basic {
        &::before{
            height: 280px;
        }
    }
}
@media (min-width: 1024px) {
    .footer-head--basic {
        &::before{
            height: 350px;
        }
    }
}
@media (min-width: 1200px) {
}
